import { gql } from "@apollo/client";

const UpdateIntegrationMutation = gql`
  mutation UpdateIntegration(
    $integrationId: ID!
    $accessToken: String
    $accountId: String
    $externalId: String
  ) {
    updateIntegration(
      integrationId: $integrationId
      input: {
        accessToken: $accessToken
        accountId: $accountId
        externalId: $externalId
      }
    ) {
      integration {
        id
        accountId
        active
        errorMessage
        externalId
        integrationType
        createdAt
        updatedAt
        createdBy {
          id
          displayId
          name
        }
      }
      error {
        message
      }
    }
  }
`;

export default UpdateIntegrationMutation;