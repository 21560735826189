import React from 'react';
import { Integrations_integrations_edges_node } from '../../generated/Integrations';
import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { Link } from 'react-router-dom';

interface IIntegrationsListItemProps {
  integration: Integrations_integrations_edges_node
}

const IntegrationsListItem = ({
  integration
}: IIntegrationsListItemProps) => {
  const classes = useStyles();
  return (
    <TableRow
      className={classes.tableRow}
      component={Link}
      hover
      to={`/tech/integrations/${integration.id}`}
    >
      <TableCell>{integration.integrationType}</TableCell>
      <TableCell>{integration.accountId}</TableCell>
      <TableCell>{integration.active ? "Active" : "Inactive"}</TableCell>
      <TableCell>
        {/* @TODO where will this come from? */}
      </TableCell>
      <TableCell>
        {/* @TODO where will this come from? */}
        &mdash;
      </TableCell>
      <TableCell>
        {integration.updatedAt
          ? new Date(integration.updatedAt).toLocaleString()
          : "&mdash;"}
      </TableCell>
      <TableCell>
        {integration.createdBy ? integration.createdBy.name : "&mdash;"}
      </TableCell>
      <TableCell>
        <ChevronRight />
      </TableCell>
    </TableRow>
  );
}

const useStyles = makeStyles(() => ({
  tableRow: {
    textDecoration: "none",
  }
}))

export default IntegrationsListItem;