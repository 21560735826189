import { useQuery } from "@apollo/client";
import React from "react";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Typography
} from "@material-ui/core";

import { useAuth0 } from "../../utils/auth0Provider";

import background from "./background.jpg";

import { InstanceSettings as IInstanceSettingsQuery } from "../../generated/InstanceSettings";
import InstanceSettingsQuery from "../../queries/InstanceSettingsQuery";
import Loading from "../Loading";

const Landing = () => {
  const classes = useStyles();

  const { loginWithRedirect, logout, user } = useAuth0();

  const { data, loading } = useQuery<IInstanceSettingsQuery>(
    InstanceSettingsQuery
  );

  const userApproved =
    user && user["https://intelitics.com/app_metadata"].approved;

  const defaultMessage = "Please log in to your partner account";
  const pendingApprovalMessage = "Your account is currently pending approval";
  const defaultDisplayName = "Intelitics Platform";

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={classes.container} style={{ background }}>
      <Card>
        <CardContent>
          <Typography variant="h5">
            {data?.instanceSettings.displayName || defaultDisplayName}
          </Typography>
          <Typography variant="body2">
            {userApproved || !user ? defaultMessage : pendingApprovalMessage}
          </Typography>
        </CardContent>
        <CardActions>
          {userApproved || !user ? (
          <Button
            className={classes.loginButton}
            color="primary"
            onClick={() => loginWithRedirect()}
            variant="contained">
            Login
          </Button>
          ) : (
          <Button
            className={classes.loginButton}
            color="primary"
            onClick={() => logout({ returnTo: window.location.origin })}
            variant="contained"
          >
            Logout
          </Button>
          )}
        </CardActions>
      </Card>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    alignItems: "center",
    background: `url(${background})`,
    backgroundSize: "cover",
    color: "white",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    minHeight: "100vh"
  },
  loginButton: {
    marginLeft: "auto",
    }
});

export default Landing;
