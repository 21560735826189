import React from "react";
import { IntercomProvider } from "react-use-intercom";

import { ApolloProvider } from "../../utils/apolloProvider";
import { Auth0Provider } from "../../utils/auth0Provider";
import getTheme from "../../utils/theme";

import Layout from "../Layout";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { MantineProvider } from '@mantine/core';

const appTheme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3AD18A",
      contrastText: "#fff"
    },
    secondary: {
      main: "#f50057",
      contrastText: "#fff"
    },
  },
});

const App = () => {
  return (
    <Auth0Provider
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      client_id={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      redirect_uri={window.location.origin}
    >
      <MuiThemeProvider theme={appTheme}>
        <MantineProvider theme={getTheme()}>
          <ApolloProvider>
            <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID!}>
              <Layout />
            </IntercomProvider>
          </ApolloProvider>
          </MantineProvider>
        </MuiThemeProvider>
    </Auth0Provider>
  );
};

export default App;
