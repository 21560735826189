import React from "react";
import {
  Breadcrumbs,
  Divider,
  Link,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import IntegrationsForm from "../../components/IntegrationForm/IntegrationsForm";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { integration as IIntegrationQuery } from "../../generated/Integration";
import IntegrationQuery from "../../queries/IntegrationQuery";
import { IntegrationType } from "../../generated/globalTypes";

const IntegrationsFormContainer = () => {
  const classes = useStyles();
  const history = useHistory();
  const formType = useLocation().pathname.includes("edit") ? "edit" : "create";
  const { integrationId } = useParams<{ integrationId?: string }>();
  const { data, loading } = useQuery<IIntegrationQuery>(
    IntegrationQuery,
    {
      variables: {
        integrationId: formType === "edit" ? integrationId : undefined,
      },
    }
  );

  const integrationData = (() => {
    if (formType === "edit" && data?.integration.integration) {
      return data.integration.integration;
    }
    const defaultData = {
      __typename: "Integration",
      id: "",
      accountId: "",
      active: false,
      externalId: "",
      integrationType: null,
    };
    return defaultData;
  })();

  const goToListView = () => history.push(
    formType === "edit" ? `/tech/integrations/${integrationId}` : "/tech/integrations"
  );
  return (
    <Paper>
      <div className={classes.header}>
        <div className={classes.headerItem}>
          <Breadcrumbs className={classes.breadcrumbs}>
            <Link
              underline="hover"
              color="inherit"
              onClick={(e) => {
                e.preventDefault();
                goToListView();
              }}
            >
              <ChevronLeft />
              Integrations
            </Link>
            <Typography color="textPrimary">
              {formType === "edit" ? "Edit Integration" : "New Integration"}
            </Typography>
          </Breadcrumbs>
        </div>
      </div>
      <Divider />
      <div className={classes.form}>
        <IntegrationsForm
          type={formType}
          id={integrationData.id}
          accountId={integrationData.accountId}
          active={integrationData.active}
          externalId={integrationData.externalId}
          integrationType={integrationData.integrationType as IntegrationType}
          onCancel={goToListView}
          loading={loading}
        />
      </div>
    </Paper>
  );
};

const useStyles = makeStyles(({ spacing, palette }) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: spacing(2),
  },
  headerItem: {
    display: "flex",
    alignItems: "center",
    gap: spacing(2),
  },
  breadcrumbs: {
    paddingBottom: spacing(1),
    "& a, & li": {
      display: "inline-flex",
      alignItems: "center",
    },
    "& a": {
      cursor: "pointer",
    },
  },
  gRoot: {
    flexGrow: 1,
  },
  form: {
    padding: spacing(2),

    "& form": {
      maxWidth: 800,
      margin: "0 auto",
    },
  },
}));

export default IntegrationsFormContainer;
